<template>
  <div class="product">
    <h1>Bitcoin Miner S19 Pro</h1>
    <img class="product-img" src="../assets/product-1.jpg" alt="" />
    <span>Plan Duration: 14 Days</span>
    <span>Mining Token: BTC</span>
    <span class="span-roi">1.50%</span>
    <a href="" class="btn">$0 Buy</a>
  </div>
</template>

<script>
export default {
  name: 'HomeProduct',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.product {
  width: 19%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  padding: 1.5rem 0.1rem;
  border-radius: 0.75rem;
  text-align: center;
}
h1 {
  font-size: 1rem;
}
span {
  font-size: 1rem;
  margin-bottom: 0.3rem;
  margin-top: 5px;
}
.product-img {
  width: 70%;
  margin: 0.5rem auto;
}
.span-roi {
  padding-top: 0.2rem;
  color: #b77e25;
  font-weight: 600;
  font-size: 1.2rem;
}
.btn {
  text-decoration: none;
  font-weight: 600;
  width: 75%;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
  border: none;
  border-radius: 1.2rem;
  background: #e2b671;
  color: #1f1f1f;
  padding: 0.5rem 1rem;
  line-height: 1;
  white-space: nowrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: 500;
}
</style>
