<template>
  <div class="slide-container">
    <div class="slide">
      <div class="info">
        <h1>Disrupting the World's Financial Economy</h1>
        <p>BitFarming is global vertically integrated Bitcoin mining operation.</p>
        <div>
          <router-link to="/home" class="btn btn-primary">LEARN MORE</router-link>
        </div>
        <div class="status-container">
          <ul class="status-list">
            <li class="status-list--item">
              <img src="../assets/icon2.png" alt="" />
              <span>~9352</span>
              <span>Miners</span>
            </li>
            <li class="status-list--item">
              <img src="../assets/icon1.png" alt="" />
              <span>7.8</span>
              <span>EH/s online</span>
            </li>
            <li class="status-list--item">
              <img src="../assets/icon3.png" alt="" />
              <span>8</span>
              <span>Farms</span>
            </li>
            <li class="status-list--item">
              <img src="../assets/icon4.png" alt="" />
              <span>23</span>
              <span>Countries</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slide-container {
  width: 100%;
  flex-shrink: 0;
  z-index: 10;
  min-height: 100px;
}
.slide {
  background: url('../assets/homepage-hero.png') no-repeat;
  background-size: cover;
  background-position: 50%;
  color: #fff;
  border-radius: 0.8rem;
  overflow: hidden;
  padding: 2rem 3rem;
  height: 23rem;
  display: flex;
  align-items: center;
}
.info {
  width: 60%;
}
h1,
h2,
h3 {
  line-height: 1.3;
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 2rem;
}
.btn {
  border: 1px solid #caa700;
  border-radius: 0.75rem;
  padding: 2px 3px;
  color: #fff;
}
.btn.btn-primary {
  background: #caa700;
  color: #fff;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #333;
  font-weight: 400;
  text-decoration: none;
}
.btn.btn-primary:hover {
  background: transparent;
  color: #caa700;
}
.status-container {
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.65);
}
.status-list {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}
.status-list--item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1.5rem;
}
.status-list--item img {
  height: 1.8rem;
  margin: 0 auto;
  padding-bottom: 0.5rem;
}
</style>
