<template>
  <div class="header">
    <div class="container">
      <div>
        <img class="logo" src="../assets/yangfarm-logo.png" alt="" />
      </div>
      <div class="nav-container">
        <div>
          <ul class="navlist">
            <li class="navlist-item"><router-link to="/">HOME</router-link></li>
            <li class="navlist-item"><router-link to="/about">ABOUT</router-link></li>
            <li class="navlist-item"><router-link to="/contact">CONTACT US</router-link></li>
            <li class="navlist-item"><router-link to="/app">APP</router-link></li>
          </ul>
        </div>
        <div>
          <router-link to="/app" class="btn btn-primary">LOGIN</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeNavbar',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
.container {
  display: flex;
  padding: 0.65rem 1.2rem;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.logo {
  max-height: 3rem;
}
.nav-container {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.navlist {
  padding: 0;
  margin: 0;
  padding-left: 3rem;
}
.navlist-item {
  padding: 0;
  margin-right: 1rem;
  list-style: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
}
.navlist-item a {
  text-decoration: none;
  color: #333;
}
.btn {
  border: 1px solid #333;
  border-radius: 0.75rem;
  padding: 2px 3px;
  color: #fff;
}
.btn.btn-primary {
  background: #333;
  color: #fff;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #333;
  font-weight: 400;
  text-decoration: none;
}
.btn.btn-primary:hover {
  background: transparent;
  color: #333;
}
</style>
