<template>
  <div class="footer">
    <div class="container page">
      <div class="footer-left">
        <img class="logo" src="../assets/yangfarm-logo-white.png" alt="" />
        <p class="footer-copy">© 2023 YANG YANG Technologies Holding Company. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeFooter',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  background: #35333d;
  padding: 1.5rem 0;
  color: #fff;
}
.container {
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
  max-width: 70rem;
  padding: 1rem;
  margin: 0 auto;
}
.logo {
  height: 3rem;
}
.footer-copy {
  font-size: 0.8rem;
}
</style>
