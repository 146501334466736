<template>
  <div class="products-container">
    <HomeProduct />
    <HomeProduct />
    <HomeProduct />
    <HomeProduct />
    <HomeProduct />
    <HomeProduct />
    <HomeProduct />
    <HomeProduct />
    <HomeProduct />
    <HomeProduct />
  </div>
</template>

<script>
import HomeProduct from './HomeProduct.vue';
export default {
  name: 'HomeProducts',
  components: {
    HomeProduct,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.products-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: stretch;
  padding-top: 3rem;
  padding-bottom: 2rem;
  min-height: 30vh;
}
</style>
