<template>
  <HomeHeroBanner />
  <HomeProducts />
</template>

<script>
import HomeHeroBanner from '../components/HomeHeroBanner.vue';
import HomeProducts from '../components/HomeProducts.vue';

export default {
  name: 'HomePage',
  components: {
    HomeHeroBanner,
    HomeProducts,
  },
};
</script>
