<template>
  <HomeNavbar msg="Welcome to Your Vue.js App" />
  <div class="content">
    <div class="page">
      <!-- route outlet -->
      <!-- component matched by the route will render here -->
      <router-view></router-view>
    </div>
    <HomeFooter />
  </div>
</template>

<script>
import HomeNavbar from './components/HomeNavbar.vue';
import HomeFooter from './components/HomeFooter.vue';

export default {
  name: 'App',
  components: {
    HomeNavbar,
    HomeFooter,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  color: #2c3e50;
  box-sizing: border-box;
  min-height: 100vh;
  overflow: hidden;
  background-color: #f6f6f6;
}
.content {
  margin-top: 4.8rem;
}
.page {
  width: 100%;
  max-width: 70rem;
  padding: 1rem;
  margin: 0 auto;
}
</style>
